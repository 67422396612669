.loginBtn {
    box-sizing: border-box;
    position: relative;
    left: 40%;
    margin: 0.2em;
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    color: #FFF;
    
}

.loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
}

.loginBtn-google {
    background: #DD4B39;
}
.loginBtn-google:before {
    border-right: #BB3F30 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}

.ou{
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 600px){
    .loginBtn{
        left: 30%;
    }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
    .loginBtn{
        left: 35%;
    }
}