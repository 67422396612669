.h1-form-event {
	color: orange;
}
.h3-form-event {
	color: black;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	font-size: 15px;
}

/*Fomulário para criar eventos*/
.inputs-event {
	margin-left: 20%;
	padding: 2%;
}

/*Carga horária e Datas*/
.input-3-event, .input-4-event {
	display: block;
}

.input-1-event {
	display: block;
}

.input-2-event {
	display: block;
}

.input-2-event {
	width: 100%;
}

/*Div em volta do pad da assinatura digital*/
.upload-assinature {
	display: block;
}

/*Botão para criar novo evento*/
.button-events {
	margin-left: 0%;
	margin-top: 3%;
	background-color: orange;
	border-color: orange;
}

.button-events:hover {
	background-color: white;
	border-color: orange;
	color: orange;
	transition: 1s;
}

/*Botão para voltar a lista de eventos dentro do form. para criar eventos*/
.button-back-from-create {
	display: flex;
	margin-left: 22%;
	margin-bottom: -30%;

	background-color: #616cd0;
    color: white;
    border-color: #616cd0;
}

.button-back-from-create:hover {
	background-color: white;
    color: #616cd0;
    border-color: #616cd0;
    transition: 0.5s;
}


@media screen and (max-width: 600px){
	/*Carga horária e Datas*/
	.input-3-event, .input-4-event {
		margin-left: 0%;
	}
	.h1-form-event, .inputs-event {
		margin-left: 0;
	}

	.button-back-from-create { 
		margin-left: 2.5%;
	}
}

@media screen and (min-width: 600px) and (max-width: 1000px){
	.inputs-event {
		margin-left: 3%;
	}

	.button-back-from-create { 
		margin-left: 5%;
	}
}