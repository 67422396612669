@import url(https://fonts.googleapis.com/css?family=Raleway:200,400,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Raleway', sans-serif;
}

.menu-1-header {
  display: block;
  background-color: transparent;
}

.logo-menu-header {
  width: 120px;
  height: 80px;
  margin-left: 10%;
}

.menu-list-header {
  display: inline-flex;
  margin-left: 45%;
}

.menu-list-header li { 
  font-size: 1rem;
  color: white;
  padding: 2px 10px;
}

.menu-list-header li:hover { 
  color: orange;
}

@media screen and (max-width: 600px) { 
  .menu-list-header { 
    margin-left: 5%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px){ 
  .menu-list-header { 
    margin-left: 20%;
  }
}
/* Inserido a foto como imagem de fundo */
.div-header {
  margin-top: 0;
  padding-bottom: 15%;
  background-image: url(/static/media/wallpaper-home.da17c689.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

/* Espaçamento do começo do texto em relação ao topo, tamanho e espaçamento das letras  */
.h2-header {
  width: 100%;
  color: #ff1493;
  margin-top: 0%;
  padding-top: 10%;
  padding-left: 20%;

  font-size: 4rem;
  /*font-family: 'Gochi Hand', cursive;*/
  /*font-family: 'Homemade Apple', cursive;*/
  font-family: 'Pacifico', cursive;
  font-weight: 790;

  word-spacing: 1.5rem;
  letter-spacing: 0.2rem;
}



@media screen and (max-width: 800px) {
  .h2-header {
    width: 100%;
    font-size: 3.8rem;
    margin-top: 20%;
    padding-top: 5%;
    text-align: center;
    padding-left: 0%;
    
  }


  .div-header {
    padding-bottom: 30%;
 
    margin-bottom: -13%;
  }
}

@media screen and (min-width: 800px) and (max-width: 1200px){
  .h2-header {
    width: 100%;
    font-size: 3.8rem;
    margin-top: 20%;
    padding-top: 5%;
    text-align: center;
    padding-left: 10%;
  } 
}

.div-section {
  background-image: url(/static/media/wallpaper-section.jpg.79c535f9.png);
  padding: 5%;
  text-align: center;
}

.h1-section {
  font-family: 'Releway', sans-serif;

  font-size: 40px;

  font-weight: bold;
}

.h2-section {
  font-family: 'Releway', sans-serif;

  font-size: 30px;

}

.span-1-home {
  color: #ffa900;
  font-weight: bold;
}

.span-2 {
  color: #006400;
  font-weight: bold;
}

.span-3 {
  border-color: #c6255a;
  border-top-style: solid;
  border-top-width: 7px;
  padding-top: 10px;
}

.span-4 {
  color: #c6255a;
  font-weight: bold;
}

.button-section {
  width: 20%;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  background-color: #c6255a;

  border-color: transparent;

  border-radius: 20px;
  color: #fff;
}

.button-section:hover {

  box-shadow: 4px 4px 8px gray;

  transition: 1s;
}

@media screen and (max-width: 800px) {
  .h2-section,
  .h1-section {
    font-size: 30px;
  }
  .span-3 {
    border-top-width: 5px;
    padding-top: 10px;
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
}

/*Titulos e textos*/
.title-1 {
	text-align: center;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
}

/*Descubra .. (h1)*/
/*Linha rosa em cima da palavra*/
.sub-title-1 {
	border-color:  #c6255a;
	border-top-style: solid;
	border-top-width: 7px;
	color: black;
	padding-top: 10px;
}

/*Gerador de certificados (h1)*/
.sub2-title-1{
	color: #c6255a;
	font-weight: 800;
}


/*Estilização dos cards e do componente Card*/

/*Para cada card*/
.card {
	margin: 3%;
}

.card:hover {
	box-shadow: 4px 4px 8px gray;
	transition: 0.5s;
}

/*Div que agrupa todos os cards*/
.site-card-wrapper {

	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding: 30px;
	margin-bottom: 3%;

	text-align: center;
	justify-content: center;
}

/*Div do componente inteiro*/
.div-cards {
	background-color: rgb(242, 242, 242);
	padding: 3%;
}

/*Quando a tela diminuir em lagura, as colunas dos cards vão se ajustando*/
@media screen and (max-width: 600px) {
	.site-card-wrapper { 
		grid-template-columns: repeat(1, 1fr);
	}
}

@media screen and (min-width: 600px) and (max-width: 1000px){
	.site-card-wrapper { 
		grid-template-columns: repeat(2, 1fr);
	}
}

.model {
  background-image: url(/static/media/wallpaper-section.jpg.79c535f9.png);

  /*Compensando o margin-top definido para o footer*/
  margin-bottom: -40%;
}

.sub-title-1 {
  border-color: #c6255a;
  border-top-style: solid;
  border-top-width: 7px;
  color: black;
}

.h1-model {
  margin-left: 3%;
  margin-top: 3%;
  font-family: 'Raleway', sans-serif;
  padding-top: 3%;
}

.model-color {
  color: #c6255a;
  font-weight: bold;
}

.modelCertificate {
  width: 50%;
  height: 50%;
  margin-top: 3%;
  margin-left: 25%;
  margin-bottom: 15%;
}

.modelCertificate:hover {
  box-shadow: 4px 4px 8px gray;
  transition: 1s;
}

@media screen and (max-width: 1280px) {
  .h1-model {
    margin-top: 6%;
    padding-top: 6%;
  }


  .modelCertificate {
    width: 70%;
    height: 70%;
    margin-left: 15%;
  }

}

.div-steps {
	margin-left: 3%;
	margin-top: 3%;
	font-family: 'Raleway', sans-serif;
}

.h2-steps {
	font-size: 30px;
	letter-spacing: 1px;
	
}

.h3-steps {
	color: #c6255a;
	font-weight: bold;
	font-size: 25px;
	letter-spacing: 2px;

}

.h4-steps {
	font-size: 20px;
	letter-spacing: 2px;
}

.span-1-steps {
	border-color: #c6255a;
	border-top-style: solid;
	border-top-width: 7px; 
	padding-top: 10px;
}

.span-2-steps {
	color: #c6255a;
}

.span-3-steps {
	color: black;
}

@media screen and (max-width: 1280px) {
	.div-steps {
		margin-left: 6%;
		margin-right: 3%;
		margin-top: 6%;
	}
	
  }
  
* {
  font-family: 'Raleway', sans-serif;
  font-weight: '200', '400', ou '800' (light, regular, bold);
}

.footer {
  background-color: rgb(17, 17, 17);

  margin-top: 35%;
  width: 100%;
}

/*Lista de desenvolvedoras*/
.footer-1 {
  display: grid;
  grid-template-columns: repeat(2, 300px);
  padding: 2%;
}

.footer-1 h3 { 
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
}

/*Licença*/
.footer-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #16181b;
  width: 100%;
  height: 10vh;
}

.footer-2 h4 {
  color: white;
}

ol, li {
  list-style: none;
}

a:hover {
  color: #c6255a;
}

.footer-link {
  color: white;
  line-height: 1.5;
  text-decoration: none;
  font-size: 1.2rem;
}

@media screen and (max-width: 660px) {
  .footer-1 {
    grid-template-columns: repeat(1, 300px);
  }

  .footer-link {
    font-size: 1rem;
  }

  .footer-1 h3 { 
    font-size: 1rem;
  }
}

.menu-1 {
  display: block;
  background-color: white;
  box-shadow: 0px 0px 4px gray;
}

.logo-menu {
  width: 110px;
  height: 70px;
  margin-left: 10%;
}

.menu-list {
  display: inline-flex;
  margin-left: 45%;
}

.menu-list li { 
  font-size: 1rem;
  color: gray;
  padding: 2px 10px;
}

.menu-list li:hover { 
  color: orange;
}

@media screen and (max-width: 760px) { 
  .menu-list { 
    margin-left: 30%;
  }
}

@media screen and (max-width: 600px) { 
  .menu-list { 
    margin-left: 20%;
  }
}
/*Bem vindo organizador*/
.title-2-list-events {
    margin-left: 85%;
    margin-top: -7%;
    font-size: 15px;
}

/*Lista de eventos*/
.title{
    display: flex;
    margin-left: 5%;
    color: #C6255A;
}


.button-add{
    border-color: rgb(0, 176, 240);
    background-color: rgb(0, 176, 240);
    color: #ffffff;
    border-radius: 5px;
    border-width: 0.2em;
    font-size: 15px;
    height: 50px;
    margin-left: 5%;
    margin-bottom: 2%;
}

.button-add:hover {
    border-color: rgb(0, 176, 240);
    color:  rgb(0, 176, 240);
    transition: 0.3s;
}

.button-profile {
    border-color: #c6255a;
    background-color: #c6255a;
    color: #ffffff;
    border-radius: 5px;
    border-width: 0.2em;
    font-size: 15px;
    height: 50px;
    margin-left: 5%;
    margin-bottom: 5%;
}

.button-profile:hover {
    border-color: #c6255a;
    color: #c6255a;
    transition: 0.3s;
}

/*Botão para voltar para a lista de eventos dentro do perfil*/
.button-back-of-profile {
    display: flex;
    margin-left: 10%;
    margin-top: 5%;
    margin-bottom: -30%;

    background-color: #616cd0;
    color: white;
    border-color: #616cd0;
}

.button-back-of-profile:hover {
    background-color: white;
    color: #616cd0;
    border-color: #616cd0;
    transition: 0.5s;
}

/*Botão para volta a lista de eventos estando na lista de participantes*/
.button-back-from-list {
    display: flex;
    background-color: #616cd0;
    color: white;
    border-color: #616cd0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -30%;
}

.button-back-from-list:hover {
    background-color: white;
    color: #616cd0;
    border-color: #616cd0;
    transition: 0.5s;
}

/*Botão para voltar para a lista de eventos*/
.button-back-of-event {
    background-color: pink;
    margin-top: -20%;
}
/*Lista de eventos - div*/
.listEvents {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: -30%;
    margin-right: auto;
    margin-left: 5%;
}

/*Para cada um dos eventos*/
.each-event {

    flex-direction: row;
    display: flex;
    width: 100%;
    margin-bottom: 3%;
}

/*Nome do evento*/
.course{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-right: 3%;
    font-weight: bold;
}

.button-check {
    border-color: transparent;
    color: gray;
}

.button-check:hover{
    border-color: transparent;
    color: #C6255A;
    transition: 0.2s;
}

/*Titulo do evento dentro do card de info.*/
.h4-list-event {
    color: pink;
    font-weight: bold;
}

/*Informações do evento dentro do card de info.*/
.span-list-event {
    color: pink;
}

.button-info {
    margin-right: 3%;
    border-color: transparent;
    color: gray;
}

.button-info:hover {
    border-color: pink;
    color: pink;
    transition: 0.2s;
}
.button-edit {
    margin-right: 1%;
    background-color: #d6815b;
    border-color: #d6815b;
    color: white;
}

.button-edit:hover {
    background-color: white;
    border-color: #d6815b;
    color: #d6815b;
    transition: 0.5s;
}

.button-delete {
    background-color: #cc0000;
    border-color: #cc0000;
    color: white;
}

.button-delete:hover {
    background-color: white;
    border-color: #cc0000;
    color: #cc0000;
    transition: 0.5s;
}

/*Formulário de edição do evento*/

/*Form inteiro*/
.edit-event {
    display: flex;
    margin: 5% auto;
    width: 50%;
    margin-bottom: -30%;
}

/*Titulo: edite seus dados*/
.edit-event-title{
    display: flex;
    justify-content: center;
}

/*Inputs do formulário*/
.edit-event-input{
    width: 100%;
    margin-bottom: 2%;
}

/*Botão para atualizar os dados*/
.button-edit-event {
    background-color: orange;
    color: white;
    border-color: orange;
    width: 50%;
    text-align: center;
    margin-bottom: 2%;

    margin-left: 25%;
}

.button-edit-event:hover {
    background-color: white;
    color: orange;
    border-color: orange;  
    transition: 0.5s;
}

/*Botão para voltar do formulário de edição para a lista de eventos*/
.back-edit-event {
    background-color: #616cd0;
    color: white;
    border-color: #616cd0;  
    width: 50%;
    margin-left: 25%;
}

.back-edit-event:hover {
    background-color: white;
    color: #616cd0;
    border-color: #616cd0;  
    transition: 0.5s;
}


@media screen and (max-width: 800px) {
    .edit-event, .back-edit-event, .button-edit-event { 
        width: 80%;
    }

    .back-edit-event, .button-edit-event { 
        margin-left: auto;
        margin-right: auto;
    }
    .each-event {
        display: block;
    }

    .listEvents { 
        grid-template-columns: repeat(1, 1fr);
        margin-left: 20%;
    }

    .title{ 
        margin-left: 22%;
    }

    .title-2-list-events {
        margin-left: 75%; 
    }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
    .edit-event, .back-edit-event, .button-edit-event { 
        width: 70%;
    }

    .back-edit-event, .button-edit-event { 
        margin-left: 15%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1000px){
    .listEvents { 
        grid-template-columns: repeat(2, 1fr);
    }
}


.h1-form-event {
	color: orange;
}
.h3-form-event {
	color: black;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	font-size: 15px;
}

/*Fomulário para criar eventos*/
.inputs-event {
	margin-left: 20%;
	padding: 2%;
}

/*Carga horária e Datas*/
.input-3-event, .input-4-event {
	display: block;
}

.input-1-event {
	display: block;
}

.input-2-event {
	display: block;
}

.input-2-event {
	width: 100%;
}

/*Div em volta do pad da assinatura digital*/
.upload-assinature {
	display: block;
}

/*Botão para criar novo evento*/
.button-events {
	margin-left: 0%;
	margin-top: 3%;
	background-color: orange;
	border-color: orange;
}

.button-events:hover {
	background-color: white;
	border-color: orange;
	color: orange;
	transition: 1s;
}

/*Botão para voltar a lista de eventos dentro do form. para criar eventos*/
.button-back-from-create {
	display: flex;
	margin-left: 22%;
	margin-bottom: -30%;

	background-color: #616cd0;
    color: white;
    border-color: #616cd0;
}

.button-back-from-create:hover {
	background-color: white;
    color: #616cd0;
    border-color: #616cd0;
    transition: 0.5s;
}


@media screen and (max-width: 600px){
	/*Carga horária e Datas*/
	.input-3-event, .input-4-event {
		margin-left: 0%;
	}
	.h1-form-event, .inputs-event {
		margin-left: 0;
	}

	.button-back-from-create { 
		margin-left: 2.5%;
	}
}

@media screen and (min-width: 600px) and (max-width: 1000px){
	.inputs-event {
		margin-left: 3%;
	}

	.button-back-from-create { 
		margin-left: 5%;
	}
}

/*Pad para assinar*/
.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 500px;
}


/*Assinatura gerada*/
.buttons-pad {
	display: block;
	margin: 0 auto;
	margin-left: 0%;
	border: 1px slid black;
	width: 150px;
}

.button-open-pad {
	color: orange;
	border-color: orange;
}

.button-save, .button-close {
	border-color: transparent;
	color: white;
}

.button-save {
	background-color: rgb(255, 91, 91);
}

.button-save:hover {
	background-color: white;
	border-color: rgb(255, 91, 91);
	color: rgb(255, 91, 91);
	transition: 0.5;
}

.button-close {
	background-color: black;
}

.button-close:hover {
	background-color: white;
	border-color: black;
	color: black;
	transition: 0.5;
}

/*Perfil do usuário*/
.profile {
	margin-top: 3%;
	margin-left: 10%;
	
}

/*Formulário de Edição*/
.edit-profile {
	display: flex;
	margin: 5% auto;
	width: 50%;
}

/*Inputs do formulário de edição do perfil*/
.input-edit-1, .input-edit-2 {
	margin-bottom: 3%;
}

.button-edit-profile {
	margin-top: 3%;
	width: 100%;
	border-color: orange;
	background-color: orange;
	color: white;
}

.button-edit-profile:hover {
	border-color: orange;
	background-color: white;
	color: orange;
	transition: 0.5;
}

/*Botão para voltar ao perfil dentro do formulario para editar perfil*/
.back-button-profile {
	margin-top: 3%;
	width: 100%;
}

.profile-title {
	text-align: center;
}

@media screen and (max-width: 800px) {
    .edit-profile { 
        width: 80%;
    }

    .button-back-of-profile {
    	margin-right: auto;
    	margin-left: auto;
    }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
	.edit-profile { 
        width: 70%;
    }
}

/*Div do titulo, lista de participantes e botão*/
.list-participants {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	padding: 3%;
	width: 100%;
}

/*Botão para adicionar participante*/
.button-add-participant {
	border-color: orange;
    background-color: orange;
    color: #ffffff;
    border-radius: 5px;
    border-width: 0.2em;
    font-size: 15px;
    height: 50px;
    margin-left: 0%;
    margin-bottom: 2%;
    margin-top: -10%;
    width: 20%;
}

.button-add-participant:hover {
	background-color: white;
	border-color: orange;
    color: orange;
    transition: 0.3s;
}

/*Botão para cancelar a presença de todos*/
.buttom-send-to-all {
	border-color: #98fb98;
    background-color: #98fb98;
    color: black;
    width: 15%;
    margin-left: 0%;
    margin-bottom: 2%;
}

.buttom-send-to-all:hover {
	border-color: #98fb98;
    background-color: #98fb98;
    color: #ffffff;
	box-shadow: 4px 4px 8px gray;
}

/*Botão para enviar e-mail a todos*/
.buttom-cancel {
	border-color: #fa7f72;
    background-color: #fa7f72;
    color: #ffffff;
    width: 15%;
    margin-left: 2%;
    margin-bottom: 2%;
}

.buttom-cancel:hover {
	border-color: #fa7f72;
    background-color: #fa7f72;
    color: #ffffff;
	box-shadow: 4px 4px 8px gray;
}

/*---------------------------------*/

/*Div de formulário*/
.input-participantes {
	text-align: center;
	width: 40%;
	margin-top: -5%;
	margin-right: auto;
	margin-left: auto;
}
/*Buttom para inserir um participante*/
.button-parcipants {
	margin-top: 3%;
	margin-bottom: 8%;
}

/*Voltar para a lista de participantes a partir do formulário*/
.buttom-voltar-participants {
	width: 45%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: -5%;
	background-color: #00b7d2;
	border-color: #00b7d2;
	color: white;
}

.button-voltar-participants:hover {
	background-color: white;
	border-color: #00b7d2;
	color: #00b7d2;
	transition: 0.5s;
}

.buttom-ver-certificado {
	margin-left: 3%;
	margin-right: 1%;
}

/*-------------------------------------*/

/*Lista de participantes*/
.title-2 {
	margin-top: 0;
	color: #c6255a;
	font-family: 'Raleway', sans-serif;
}


@media screen and (max-width: 600px) {
	.input-participantes {
		width: 100%;
	}

	.buttom-voltar-participants {
		margin-bottom: 1%;
	}
}


@media screen and (max-width: 800px) {
	.list-participants { 
		grid-template-columns: repeat(1, 1fr);
	}

	.title-2 {
		margin-top: 5%;
	}

	.input-participantes {
		width: 70%;
	}

	.buttom-voltar-participants {
		margin-bottom: 1%;
	}

	.button-add-participant {
		width: 30%;
	}

	.buttom-send-to-all, .buttom-cancel { 
		width: 20%;
	}
}

@media screen and (min-width: 800px) and (max-width: 1000px){
	.input-participantes {
		width: 70%;
	}

	.buttom-voltar-participants {
		margin-bottom: -2%;
	}

	.button-add-participant {
		width: 30%;
	}

	.buttom-send-to-all, .buttom-cancel { 
		width: 25%;
	}
}


.certificate-background {
	text-align: center;
	align-items: center;
	justify-content: center;
	font-family: 'Raleway', sans-serif;
	font-weight: 200;
	font-size: 1.5vw;

	background: url(/static/media/wallpaper-certificate.b441a637.png);
	background-repeat: no-repeat;
	background-size: 100%;

	margin-left: 10%;
	margin-right: 10%;
	margin-top: 5%;
	margin-bottom: 10%;
}

/*Logo*/
.img-logo-certificate {
	display: flex;
	margin-left: 40%;
	margin-top: 10%;
	width: 15%;
}

/*Estrelas*/
.img-stars {
	display: flex;
	margin-left: 45%;
	width: 70px;
	height: 15px;
} 

/*Texto*/
.p-certificate {
	margin-left: 5%;
	margin-top: 5%;
	width: 90%;
}

/*Assinatura Digital*/
.digitalSignature {
	width: 80px;
	height: 80px;
}

hr {
	width: 35%;
	color: black;
	margin-top: 0%;
	margin-left: 30%;
	background-color: brown;
}

/*Colocando as informações principais do certificado em negrito*/
.info-certificate {
	font-weight: 400;
	color: black;
}

/*Nome do organizador do evento*/
.p-2-certificate {
	margin-left: 32%;
	margin-bottom: 10%;

	width: 30%;
}

/*Botão para voltar a lista de participantes*/
.button-voltar {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 3%;

	background-color: #00b7d2;
	border-color: #00b7d2;
	color: white;
}

.button-voltar:hover {
	background-color: white;
	border-color: #00b7d2;
	color: #00b7d2;
	transition: 0.5s;
}

.button-email {

	display: flex;
	margin-left: auto;
	margin-right: auto;
	margin-top: -5%;
	margin-bottom: 3%;

	background-color: #50c878;
	border-color: #50c878;
	color: white;
}

.button-email:hover {
	background-color: white;
	border-color: #50c878;
	color: #50c878;
	transition: 0.5s;
}

@media screen and (max-width: 700px) {
	.div-buttons { 
		grid-template-columns: repeat(1, 1fr);
	}
}

@media screen and (max-width: 800px) {
	.img-logo-certificate {
		margin-top: 10%;
		margin-left: 46.5%;
		width: 10%;
		height: 10%;
	}

	.img-stars {
		width: 35px;
		height: 7.5px ;
		margin-left: 47%;
	}

	/*Assinatura Digital*/
	.digitalSignature {
		width: 20px;
		height: 20px;
	}
}

@media screen and (min-width: 800px) and (max-width: 1000px){
	.img-logo-certificate {
		margin-top: 5%;
		margin-left: 40%;
	}

	.button-pdf {
		background-color: pink;
	}

	/*Assinatura Digital*/
	.digitalSignature {
		width: 40px;
		height: 40px;
	}
}

.overlay-content {
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.7);
  flex-direction: column;
}

.overlay-content .wrapper {
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay-content .wrapper .message {
  margin-top: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 0 2px 5px black;
}

/* react-spinkit */

.sk-spinner.pacman > div:nth-child(n+3):nth-child(-n+5) {
  color: white;
  z-index: -1;
}

/*Wallpaper*/
.up-info {
	background-color: chartreuse;
	width: 100%;
	height: 60vh;
	margin-top: -10%;
} 

/*Lista com as informações do evento*/
.list-info {
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 5%;
	
}
.button-form-login {
	background-color: pink;
    border-color: pink;
    margin-right: 15%;
}


.article {
	width: 40%;
	margin-right: auto;
	margin-left: auto;
}

.title-login {
    color: #c6255a;
    text-align: center;
}

.login-form {
	margin-bottom: -20%;
}

@media screen and (max-width: 900px) {
	.article { 
		width: 70%;
	}

	.loginBtn.loginBtn-google {
		margin-left: 10%;
	}
}

@media screen and (max-width: 700px) {
	.article { 
		width: 70%;
	}
}

@media screen and (min-width: 700px) {
	.login-form {
		margin-bottom: -30%;
	}
}

.loginBtn.loginBtn-google {
	margin-left: -5%;
}

.ou {
	display: flex;
	justify-content: center;
	margin-top: 3%;
}


@media screen and (max-width: 700px){ 
	.loginBtn.loginBtn-google {
		margin-left: -10%;
	}
}
*{
    font-family: 'Raleway', sans-serif;
    font-weight: "200", "400", ou "800" (light, regular, bold)
}

.form {
    width: 70%;
    height: 50vh;
    display: block;
    padding: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -10%;
    margin-top: 5%;
}

/*Inputs - Label*/
.form-label{
    font-family: 'Raleway', sans-serif;
    font-weight: light;
    font-size: 1.2em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 0.5em;

}

.title-cadastre {
    color: orange;
    text-align: center;
}

/*Inputs - Field*/
.form-field {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    width: 60%;
    margin-bottom: 2%;
    padding: 1%;
    border-color: transparent;
    border-bottom-color: #d3d3d3;
    border-width: 0.5px;
    
}

.form-upload{
    display: block;
    margin-left: 28%;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 40px;
    border-color: rgb(255, 102, 0);
    color: rgb(255, 102, 0);
    font-weight: bold;
}

.button {
    background-color: orange;
    border-color: transparent;
    margin-left: 20%;
    color: #ffffff;
    width: 60%;
}

.button:hover {
    border-color: rgb(255, 102, 0);
    color: rgb(255, 102, 0);
    background-color: white;
    transition: 1s;
}

/*Mensagem de erro: preencha os campos*/
.msg-error {
    color: red;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 600px){
    .form{
        width: 90%;
        height: 60vh;
    }

    .form-upload{
        margin-left: 15px;
    }

    .button{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .form-field {
        width: 100%;
        margin-bottom: 5%;
    }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
    .form{
        width: 65%;
        height: 60vh;
    }

    .form-upload{
        margin-left: 22%;
    }

    .button{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .form-field {
        width: 100%;
        margin-bottom: 5%;
    }
}
.loginBtn {
    box-sizing: border-box;
    position: relative;
    left: 40%;
    margin: 0.2em;
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    color: #FFF;
    
}

.loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
}

.loginBtn-google {
    background: #DD4B39;
}
.loginBtn-google:before {
    border-right: #BB3F30 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}

.ou{
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 600px){
    .loginBtn{
        left: 30%;
    }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
    .loginBtn{
        left: 35%;
    }
}
.h3-success {
    text-align: center;
    font-family: 'Releway', sans-serif;
    font-size: 30px;
    font-weight: 300;
    color: black;
    display: block;
    margin: 20px auto;
    margin-top: 15%;
    position: relative;
}

.span-success {
    color: #c6255a;
    font-weight: bold;
}

@media screen and (max-width: 800px) {
    .h3-success{
        font-size: 20px;
    }
    .span-success {
        font-size: 20px;
    }
}
img.gif-erro {
    width: 420px;
    height: 400px;
    display: block;
    margin: 20px auto;
    position: relative;
  }

