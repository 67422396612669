.loginBtn.loginBtn-google {
	margin-left: -5%;
}

.ou {
	display: flex;
	justify-content: center;
	margin-top: 3%;
}


@media screen and (max-width: 700px){ 
	.loginBtn.loginBtn-google {
		margin-left: -10%;
	}
}