* {
  font-family: 'Raleway', sans-serif;
  font-weight: '200', '400', ou '800' (light, regular, bold);
}

.footer {
  background-color: rgb(17, 17, 17);

  margin-top: 35%;
  width: 100%;
}

/*Lista de desenvolvedoras*/
.footer-1 {
  display: grid;
  grid-template-columns: repeat(2, 300px);
  padding: 2%;
}

.footer-1 h3 { 
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
}

/*Licença*/
.footer-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #16181b;
  width: 100%;
  height: 10vh;
}

.footer-2 h4 {
  color: white;
}

ol, li {
  list-style: none;
}

a:hover {
  color: #c6255a;
}

.footer-link {
  color: white;
  line-height: 1.5;
  text-decoration: none;
  font-size: 1.2rem;
}

@media screen and (max-width: 660px) {
  .footer-1 {
    grid-template-columns: repeat(1, 300px);
  }

  .footer-link {
    font-size: 1rem;
  }

  .footer-1 h3 { 
    font-size: 1rem;
  }
}
